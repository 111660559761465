import icon192 from "../../public/assets/images/manifest-icon-192.png";
import icon512 from "../../public/assets/images/manifest-icon-512.png";
import contact from "../../public/assets/images/contact.png";
import placeHolder from "../../public/assets/images/emptyImage.png";
import shipments from "../../public/assets/images/shipment.png";
export const images = {
  icon192: icon192.src,
  icon512: icon512.src,
  placeHolder: placeHolder.src,
  contact: contact.src,
  shipments: shipments.src,
};
